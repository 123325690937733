.btn {

  border: 2px solid #3d3c3c;
  padding: 2px;
 

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  transition: 1s;
}

.btn::before, .btn::after {
  position: absolute;
  background:rgb(26, 25, 25);
  z-index: -1;
  transition: 1s;
  content: '';
}

.btn::before {
  height: 100%;
  width: 100%;
}

.btn::after {
  width: 100%;
  height: 100%;
}

.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.btn:hover::before {
  width: 0px;
  /* background: #fff; */
}

.btn:hover::after {
  height: 0px;
  /* background: #fff; */
  padding:2px rgb(236, 240, 21);
}

.btn:hover {
  /* background: #fff; */
  border-color: rgb(11, 156, 204);
  box-shadow:2px 2px 5px  rgb(236, 240, 21);
}