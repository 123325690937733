.customCard{
    display:flex;
    height:100px;
    width:80px;
    border:2px solid yellow
}






.bgImg{
  

width:100%;
background-color: #000;


}
/* coloured block */
.block {
    position: relative;

   z-index: 3;
    /* width: 500px;
    height: 250px; */
    background: linear-gradient(0deg, #000, #272727);
  }
  
  .block:before, .block:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
      #0000ff, #00ff00,#ffff00, #ff0000);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 20s linear infinite;
  }
  
  @keyframes steam {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .block:after {
    filter: blur(50px);
  }
